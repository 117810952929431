.footer {
  background-color: #fff;

  &-address p {
    margin-bottom: 0.25rem;
  }

  &-icon-link {
    margin-right: $spacer;
    text-decoration: none;
  }

  &-icon-link:last-child {
    margin-right: 0;
  }
}
