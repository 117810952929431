// -----------------------------------------------------------------------------
// Application-wide variables
// -----------------------------------------------------------------------------

// Path to fonts and images folders, relative to app.scss
$font-path: '../fonts';
$img-path: '../img';

//
// Bootstrap
//
// see: ../../node_modules/bootstrap/scss/_variables.scss

// Color system
// You could generate a color scheme with: https://palx.jxnblk.com

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

// Options

$primary: #da692c;
$secondary: #466895;
$body-bg: #eeebea;

$enable-validation-icons: false;

$font-weight-thin: 100;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
