$sizes: (
  'small': 1em,
  'medium': 2em,
  'big': 3em,
  'large': 4em,
);

.icon {
  display: inline-block;
  fill: currentColor;

  @each $sizename, $size in $sizes {
    &-#{$sizename} {
      width: $size;
      height: $size;
    }
  }

  &-btn-horizontal {
    width: (2 * $input-btn-padding-y + $btn-line-height) * 2; // height * 2
    height: 2 * $input-btn-padding-y + $btn-line-height;
  }
}

.text-handwritten {
  font-family: Caveat, sans-serif;
}

.text-gray {
  @each $name, $val in $grays {
    &-#{$name} {
      color: $val;
    }
  }
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.bordered-title {
  padding-left: 0.5em;
  border-left: 2px solid $primary;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}
