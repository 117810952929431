.repas {
  .lead {
    font-size: 1.75em;
  }

  &__title {
    margin: 1em 0 0.5em;
    font-size: 3em;
  }

  &__tuile {
    &__fourchette {
      transform: rotate(-90deg);
    }
  }

  &__form {
    .form-group {
      margin: 0.5em 0;
    }

    input,
    textarea,
    input:focus,
    textarea:focus {
      background-color: $white;
    }

    .btn {
      width: 50%;
      margin: 1em 0;
      color: $white;
    }
  }

  &__tel {
    margin: 4em 0 0;
    transform: rotate(-15deg);

    &__image {
      width: 60%;
      margin: 1em 0;
    }

    .title {
      font-size: 2.5em;
    }
  }
}

.card {
  margin: 0.5em 0;
  overflow: hidden;

  &-tags {
    margin-bottom: 0.5em;
  }

  &-event-img {
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

// Date de l'évènement
.event-dates {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  height: 100%;
  padding: 0.6rem;
  color: $white;
  background-color: $secondary;

  @include media-breakpoint-up(sm) {
    min-width: 100px;
    padding: 1rem;
  }

  > * {
    flex: 0 0 auto;
  }
}

.event-date-separator {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin: 0.5rem auto;
  font-size: 0.75rem;
  line-height: 1;
  color: rgba($white, 0.7);

  // Trait de séparation à gauche et à droite d'un texte éventuel
  &::before,
  &::after {
    display: inline-block;
    flex: 1 1 0px; // stylelint-disable-line length-zero-no-unit
    content: '';
    border-top: 1px solid rgba($white, 0.5);
  }
}

.event-date-day,
.event-date-month,
.event-time {
  display: block;
  font-family: $headings-font-family;
  font-weight: $font-weight-medium;
  line-height: 1;
  text-align: center;
}

.event-date-day {
  font-size: 3rem;

  // Trait de séparation entre le jour et le mois
  &::after {
    width: 90%;
    height: 4px;
    margin: 0 auto 0.75rem;
    content: '';
    background-color: $white;
    border-radius: 2px;
  }

  .with-ending & {
    font-size: 2.5rem;

    &::after {
      height: 3px;
      margin-bottom: 0.5rem;
    }
  }

  @include media-breakpoint-up(sm) {
    font-size: 4rem;
  }
}

.event-date-month {
  font-size: 1.5rem;
  font-weight: $font-weight-extrabold;
  text-transform: uppercase;

  .with-ending & {
    font-size: 1rem;
    font-weight: $font-weight-medium;
  }

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
}

.event-time {
  width: 100%;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
  font-size: 1.15rem;
  border-top: 2px solid rgba($white, 0.5);

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
  }
}
