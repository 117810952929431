.container .calendar {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding-top: 75%; /* 16:9 Aspect Ratio */
  }

  @include media-breakpoint-down(sm) {
    padding-top: 100%; /* 16:9 Aspect Ratio */
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
