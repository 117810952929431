.header {
  margin: 0 0 2em;
  background-color: #d6d3d2;

  li {
    list-style-type: none;
  }

  .navbar-toggler:focus {
    box-shadow: 0 0 0 0.1rem $primary;
  }

  .menuitem-titre {
    height: 50px;
    font-size: smaller;
    font-weight: 700;
    color: $secondary;
    text-transform: uppercase;
  }
}
