// -----------------------------------------------------------------------------
// Forms component's extension
// -----------------------------------------------------------------------------

// Indicate that a form field is required.
.required {
  font-size: 90%;
  color: $danger;
}

.form-control {
  background-color: #fff;
}

.newsletter-form {
  @include media-breakpoint-up(lg) {
    .wrapper {
    max-width: 60%;
    margin: auto;
    }
  }
}