$carousel-border-color: rgba($black, 0.2);

$carousel-item-image-width-md: 70%;
$carousel-item-image-height: 290px;
$carousel-item-image-height-lg: 600px;

.carousel {
  @include media-breakpoint-up(sm) {
    padding-bottom: 2em;
  }
  margin-bottom: 2em;

  .carousel-inner,
  .carousel-item,
  .carousel-item-content {
    height: 100%;
  }

  .carousel-indicators {
    margin-bottom: 0;

    button {
      width: 12px;
      height: 12px;
      background-color: $body-bg;
      border: 1px solid $primary;
      border-radius: 50%;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .carousel-indicators .active {
    background-color: $primary;
  }

  .carousel-control-prev,
  .carousel-control-next {
    align-items: flex-end;
    padding-bottom: 1.25rem;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23da692c%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e');
  }

  .carousel-control-next-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23da692c%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  }

  .carousel-item-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > * {
      position: relative;
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
    }

    &.has-image {
      .carousel-item-text {
        margin-top: -56px;
      }
    }
  }

  .carousel-item-image {
    height: $carousel-item-image-height;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .carousel-item-text {
    width: 100% - $carousel-control-width * 2;
    padding: 1rem;
    text-align: center;
    background-color: $body-bg;
  }

  @include media-breakpoint-up(md) {
    .carousel {
      // Ajoute une bordure intérieure en haut et en bas
      &::before,
      &::after {
        position: absolute;
        z-index: 10;
        width: 100%;
        content: '';
        border-top: 1px solid $carousel-border-color;
      }
      &::after {
        bottom: 0;
      }
    }

    .carousel-item-content {
      &.has-image {
        // Ajoute une bordure intérieure à droite s'il y a une image
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 10;
          height: 100%;
          content: '';
          border-left: 1px solid $carousel-border-color;
        }

        .carousel-item-text {
          margin-top: 0;
        }
      }
    }

    .carousel-item-image {
      flex: 0 0 auto;
      width: $carousel-item-image-width-md;
    }

    .carousel-item-text {
      flex: 1 0 0%;
      order: -1;
      padding: 2rem 1rem;
      text-align: left;
    }
  }

  @include media-breakpoint-up(lg) {
    .carousel-item-image {
      height: $carousel-item-image-height-lg;
    }
  }
}
